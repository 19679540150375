<template lang="pug">
div
      // General product group
      vs-row(vs-w='12')
        template(v-if="thisImage")
          .img-container.w-64.mx-auto.flex.items-center.justify-center.pt-12(vs-type='flex', vs-justify='flex-start', vs-align="flex-start")
            img.pb-6.responsive(:src="getImagepath(thisImage)" alt="img" style="width: 70%")
            i.material-icons.px-1(style=" cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle;"  @click="removeItemImage()" ) cancel
        template(v-else)
          template(v-if="dataImg")
            // Image Container
            .img-container.w-64.mx-auto.flex.items-center.justify-center(vs-type='flex', vs-justify='flex-start', vs-align="flex-start")
              img.responsive(:src="dataImg" alt="img" style="width: 70%")
              i.material-icons.px-1(style=" cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle;" @click="removeItemImage()" ) cancel

          // Image upload Buttons
          vs-col.px-6.pb-6.pt-6( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            .upload-img.mt-5(v-if="!dataImg")
              input.hidden(type="file" ref="mainUploadImgInput" @change="updateCurrImg" accept="image/*")
              .upload_image(@click="$refs.mainUploadImgInput.click()" vs-type='flex', vs-justify='flex-start', vs-align='flex-start' )

            // Image upload Buttons เส้นประ
            .modify-img.flex.justify-between.mt-3
              input.hidden(type="file" ref="updateImgInput" @change="updateCurrImg" accept="image/*")

</template>
<script>
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import CurrencyValue from "./Components/CurrencyValue";
import moduleProductGroup from "@/store/product-group/moduleProductGroup.js";
import shapeFormat from "@/mixins/shapeFormat.js";

export default {
  props: {
    // list: Array,
    productGroupId: String,
    color: String,
    productItemId: String,
    productList: Array,
    // viewform: Array,
    // init: Object
  },
  mixins: [shapeFormat],
  data() {
    return {
      dataImg: "",
      thisImage: null,

      productGroup: {},
      images: []
    };
  },
  async created() {
    // INIT productGroup
    this.productGroup = moduleProductGroup.state.productGroup;


    // console.log(" *********** >>>>>>>>. productLists >>>> ", this.productList.map( x => x.mainImage));
    // console.log(" *********** >>>>>>>>. productLists >>>> ", this.productList.map( x => x.code));

    var result = this.productList.filter(x => x.color === this.color);
    // console.log("@@@@@@@ AddImageComponent result.color >>>> ", result[0].color, " result.mainImage >>>> ",result[0].mainImage );
    if (result.length > 0) {
      this.thisImage = result[0].mainImage;
    } else {
      this.thisImage = null;
    }

    // console.log(
    //   "COLOR >>>> ",
    //   this.color,
    //   " itemimages filename >>>> ",
    //   this.thisImage
    // );
  },
  watch: {
    // productGroup: {
    //   handler(value) {
    //     console.log("handler >>> ", value);
    //   },
    //   deep: true
    // }
  },
  methods: {
    removeItemImage() {
      this.$swal({
        title: "ต้องการลบรูปใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรูป"
      }).then(async result => {
        if (result.value) {
          this.thisImage = null;
          this.dataImg = null;
          try {
            await this.$http.post(
              this.$store.state.apiURL +
                "/api/productgroup/remove-product-image",
              { t1: this.productGroupId, t2: this.color },
              this.$store.state.auth.apiHeader
            );
          } catch (error) {
            this.$swal({
              icon: "error",
              title: "ไม่สามารถลบได้"
              // text: 'Something went wrong!',
            });
          }

          // this.deleteImage(imageid, indextr);
        }
      });
    },
    async updateCurrImg(input) {
      // this.$vs.loading({ type: "radius" });

      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);

        this.images.unshift(input.target.files[0]);

        this.$store.dispatch("productList/addImage", input.target.files[0]);

        const formData = new FormData();
        formData.append("productGroupId", this.productGroupId);
        formData.append("color", this.color);
        formData.append("productItemId", this.productItemId);
        formData.append("file", input.target.files[0]);

        //*** BEGIN: UPDATE 2021-02-28

        var resp = null;

        try {

              resp = await this.$http.post(
              this.$store.state.apiURL +
                "/api/item-image-upload/upload-product-item",
              formData,
              this.$store.state.auth.apiHeader
            );
            var result = {
              "color" : this.color,
              "productGroupId" : this.productGroupId,
              "fileName" :resp.data
            };

            this.$store.dispatch("productList/addImage", result);
        }
        catch(err){
          this.thisImage = null;
          this.dataImg = null;
          this.$swal({
              icon: "warning",
               title: err.response.data,
              // text: err.response.data,
            });
        }

      //*** END: UPDATE 2021-02-28

      }
    }

  },
  components: { Multiselect, "v-select": vSelect, CurrencyValue }
};
</script>
<style>
</style>
